import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { store } from "../redux/store";
import {
  setApartmentName,
  setFlat,
  setInternal,
} from "../redux/slices/apartmentSlice";
import ApartmentCard from "./ApartamentCard";
import IwdDataGrid from "./utils/IwdDataGrid";

import theme from "./theme";

const ApartamentIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getAppartments] = store.useLazyGetApartmentsQuery();
  const apartments = useSelector((state) => state?.apartmentSlice?.apartments);
  const [sorts, setSorts] = useState([]);

  useEffect(() => {
    getAppartments();
    dispatch(setApartmentName(null));
    dispatch(setFlat(null));
    dispatch(setInternal(null));
  }, []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const apartmentMode = useSelector((state) => state?.authSlice?.apartmentMode);

  const columns = [
    {
      field: "id",
      headerName: t("id"),
      hide: true,
      sortable: false,
      editable: false,
      flex: 2,
    },
    {
      field: "block",
      headerName: t("block"),
      flex: 2,
      editable: false,
      sortable: false,
    },
    {
      field: "subblock",
      headerName: t("subcondominum"),
      flex: 2,
      editable: false,
    },
    {
      field: "apartment",
      headerName: t("apartment"),
      flex: 2,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <IconButton
              onClick={() => {
                navigate(`/apartment/${params?.value?.id}`);
                dispatch(setApartmentName(params?.value?.name));
                dispatch(setFlat(params?.value?.flat));
                dispatch(setInternal(params?.value?.internal));
              }}
            >
              <VisibilityIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const rows = apartments?.map((item) => ({
    id: item?.fields?.identifier,
    block: item?.condominium_name,
    subblock: item?.sub_condominium_name,
    apartment: item?.fields?.name,
    actions: {
      id: item?.fields?.identifier,
      name: item?.fields?.name,
      flat: item?.fields?.flat,
      internal: item?.fields?.internal,
    },
  }));

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  return (
    <>
      {apartmentMode == "table" && (
        <Box>
          <IwdDataGrid
            rows={rows}
            columns={columns}
            pageSize={perPage}
            page={page}
            setPage={setPage}
            paginationMode="client"
            rowCount={rows?.length}
            height={700}
            sorts={sorts}
            setSorts={setSorts}
          />
        </Box>
      )}
      {apartmentMode == "card" && (
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={isMobile ? "center" : "start"}
        >
          {apartments?.map((item) => (
            <ApartmentCard
              key={item?.fields?.identifier}
              id={item?.fields?.identifier}
              block={item?.condominium_name}
              subblock={item?.sub_condominium_name}
              apartment={item?.fields?.name}
              flat={item?.fields?.flat}
              internal={item?.fields?.internal}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default ApartamentIndex;
