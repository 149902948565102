import ApartmentConsumptionTable from "./ApartmentConsumptionTable";
import { Box, Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { store } from "../redux/store";
import HcaConsumtionsGraph from "./graphs/HcaConsumptionGraph";
import ConsumptionGrafic from "./ConsumptionGrafic";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MonthSelect from "./utils/MonthSelect";
import WaterTable from "./tables/WaterTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "./theme";
import ColorTable from "./tables/ColorTable";
import HcaTable from "./tables/HcaTable";
import WaterReadingsGraph from "./graphs/WaterReadingsGraph";
import ColorMeterReadingGraph from "./graphs/ColorMeterReadingGraph";
import WaterMeterConsumptionsGraph from "./graphs/WaterMeterConsumptionsGraph";
import WaterConsumptionsTable from "./tables/WaterConsumptionsTable";
import HeatConsumptionsTable from "./tables/HeatConsumptionsTable";
import { useEffect } from "react";
import HcaConsumtionsTable from "./tables/HcaConsumtionsTable";
import HeatConsumptionsGraph from "./graphs/HeatConsumptionsGraph";

const MeterShow = () => {
  const { t } = useTranslation();
  const type = useSelector((state) => state?.meterSlice?.meterType);
  const meter = useSelector((state) => state?.meterSlice?.meterShow);
  const alarms = useSelector((state) => state?.meterSlice?.allarms);
  const consumptions = useSelector((state) => state?.meterSlice?.consumptions);
  const internal = useSelector((state) => state?.apartmentSlice?.internal);
  const flat = useSelector((state) => state?.apartmentSlice?.flat);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 600;
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Card sx={{ height: "auto" }}>
          <CardContent>
            <Typography
              sx={{ mb: 2, color: theme?.palette.primary.main }}
              variant="h2"
            >{`${t("meter")}: ${meter?.serial_number}`}</Typography>
            <Grid
              alignItems="center"
              rowSpacing={1}
              direction="row"
              spacing={1}
              container
              sx={{ mb: 10, mt: 2 }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {t("serial_number")} :
                </Typography>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {meter?.serial_number ?? "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {t("condominium")} :
                </Typography>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {meter?.fields?.condominium_name ?? "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {t("subcondominum")} :
                </Typography>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {meter?.fields?.sub_condominium_name ?? "NA"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography color={theme.palette.primary.main} variant="h6">
                  {t("apartment_name")} :
                </Typography>

                <Typography color={theme.palette.primary.main} variant="h6">
                  {meter?.fields?.apartment_name ?? "NA"}
                </Typography>
              </Grid>

              {flat && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    color={theme.palette.primary.main}
                    variant="h6"
                  >{`${t("flat")}: ${flat}`}</Typography>
                </Grid>
              )}
              {internal && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography
                    color={theme.palette.primary.main}
                    variant="h6"
                  >{`${t("internal")}: ${internal}`}</Typography>
                </Grid>
              )}
              {meter?.fields?.room && (
                <Grid item xs={12} sm={6} md={3}>
                  <Typography color={theme.palette.primary.main} variant="h6">
                    {t("room")} :
                  </Typography>
                  <Typography color={theme.palette.primary.main} variant="h6">
                    {meter?.fields?.room}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3}>
                <Typography color={theme.palette.error.main} variant="h6">
                  {t("alarms")} :
                </Typography>
                {alarms?.map((item, index) => (
                  <div key={index}>
                    {item?.date_times_errors.map((item, index) => (
                      <Typography
                        key={index}
                        color={theme.palette.error.main}
                        variant="subtitle2"
                      >{`${item?.[0]} : ${t("date_val", {
                        val: new Date(item?.[1]),
                      })}`}</Typography>
                    ))}
                  </div>
                ))}
              </Grid>
            </Grid>
            <MonthSelect />
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ mt: 2 }}>
        {consumptions?.length > 0 && (
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Graphic
            </AccordionSummary>
            <AccordionDetails>
              {type == "07" && (
                <Box display={"flex"} height={500}>
                  <WaterMeterConsumptionsGraph />
                </Box>
              )}
              {type == "13" && (
                <Box display={"flex"} height={500}>
                  <HeatConsumptionsGraph />
                </Box>
              )}
              {type == "08" && (
                <Box display={"flex"} height={500}>
                  <HcaConsumtionsGraph />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Box sx={{ mt: 10 }}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Tables
          </AccordionSummary>
          <AccordionDetails sx={{ minHeight: "30rem", overflow: "auto" }}>
            {type === "07" && (
              <Grid container spacing={2} height={600}>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <WaterTable />
                  </Box>
                </Grid>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <WaterConsumptionsTable />
                  </Box>
                </Grid>
              </Grid>
            )}
            {type === "13" && (
              <Grid container spacing={2} height={600}>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <ColorTable />
                  </Box>
                </Grid>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <HeatConsumptionsTable />
                  </Box>
                </Grid>
              </Grid>
            )}
            {type === "08" && (
              <Grid container spacing={2} height={600}>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <HcaTable />
                  </Box>
                </Grid>
                <Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
                  <Box sx={{ height: 600, overflow: "auto" }}>
                    <HcaConsumtionsTable />
                  </Box>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
export default MeterShow;
