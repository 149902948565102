import BsLogo from "./BsLogo";
import LogoMad from "./LogoMad";

export const selectLogo = () => {

  switch (window.MAINLOGO) {
    case "bs":
      return <BsLogo />;
    case "mad":
      return <LogoMad />;
  }
};
