import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  apartments: null,
  devices: null,
  apartmentName: localStorage.getItem("app_name"),
  flat: null,
  internal: null,
};

const apartmentSlice = createSlice({
  name: "apartmentSlice",
  initialState,
  reducers: {
    setApartments(state, action) {
      state.apartments = action.payload;
    },
    setFlat(state, action) {
      state.flat = action.payload;
    },
    setInternal(state, action) {
      state.internal = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setApartmentName(state, action) {
      state.apartmentName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.apartmentEndpoints.getApartments.matchFulfilled,
      (state, action) => {
        state.apartments = action.payload.data;
      }
    );
    builder.addMatcher(
      store.apartmentEndpoints.getApartmentDevices.matchFulfilled,
      (state, action) => {
        state.devices = action.payload.data;
      }
    );
  },
});
export const {
  setApartments,
  setDevices,
  setApartmentName,
  setFlat,
  setInternal,
} = apartmentSlice.actions;
export default apartmentSlice.reducer;
