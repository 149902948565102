import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import HouseIcon from "@mui/icons-material/House";
import theme from "./theme";
import { useNavigate } from "react-router-dom";
import {
  setApartmentName,
  setFlat,
  setInternal,
} from "../redux/slices/apartmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const ApartmentCard = ({ block, subblock, apartment, id, flat, internal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCardClick = (id) => {
    navigate(`/apartment/${id}`);
    dispatch(setApartmentName(apartment));
    dispatch(setFlat(flat));
    dispatch(setInternal(internal));
    localStorage.setItem("app_name", apartment);
  };
  return (
    <Card
      onClick={() => onCardClick(id)}
      sx={{
        width: 330,
        margin: 2,
        borderRadius: 8,
        cursor: "pointer",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
        background: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CardContent
        sx={{
          mt: 3,
          padding: "16px",
          textAlign: "center",
          overflowY: "auto",
          height: 200,
        }}
      >
        <HouseIcon
          sx={{
            color: "#E0E0E0",
            filter: "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))",
            marginBottom: "16px",
          }}
        />
        <Typography
          color={"#E0E0E0"}
          variant="subtitle1"
          gutterBottom
          sx={{
            wordBreak: "break-all",
            overflowWrap: "break-word",
          }}
        >
          {`${t("block")}: ${block}`}
        </Typography>
        <Typography
          color={"#E0E0E0"}
          variant="subtitle1"
          gutterBottom
          sx={{
            wordBreak: "break-all",
            overflowWrap: "break-word",
          }}
        >
          {`${t("subcondominum")}: ${subblock}`}
        </Typography>
        <Typography
          color={"#E0E0E0"}
          variant="subtitle1"
          sx={{
            wordBreak: "break-all",
            overflowWrap: "break-word",
          }}
        >
          {`   ${t("apartment")}: ${apartment}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ApartmentCard;
