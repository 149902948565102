import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box, Avatar, Divider } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import { AppBar, Toolbar, IconButton, Tooltip, Button } from "@mui/material";
import theme from "./theme";
import { useSelector } from "react-redux";
import ModalDialog from "./utils/ModalDialog";
import ChangePasswordModal from "./ChangePasswordModal";

const UserProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  const [getMe] = store.useLazyGetMeQuery();
  const [deleteAccount, { isSuccess }] = store.useDeleteAccountMutation();
  const useInfo = useSelector((state) => state?.authSlice?.useInfo);
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/registration`);
    }
  }, [isSuccess]);

  const openPasswordModal = () => {
    setPasswordModal(true);
  };
  const closePasswordModal = () => {
    setPasswordModal(false);
  };

  return (
    <>
      <ChangePasswordModal
        visible={passwordModal}
        onClose={closePasswordModal}
      />
      {useInfo && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
            padding: 2,
          }}
        >
          <ModalDialog
            open={open}
            title={t("del_user")}
            close={() => setOpen(false)}
            cancell={false}
          >
            <Box>
              <Typography>{t("del_user_txt")}</Typography>
              <Box
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  onClick={deleteAccount}
                  sx={{ color: theme.palette.error.main }}
                >
                  {t("yes")}
                </Button>
                <Button onClick={() => setOpen(false)}>{t("no")}</Button>
              </Box>
            </Box>
          </ModalDialog>
          <Paper
            sx={{
              padding: 4,
              maxWidth: 600,
              width: "100%",
              margin: 2,
              boxShadow: 3,
              backgroundColor: "#ffffff",
              borderRadius: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
              <Avatar sx={{ bgcolor: "primary.main", width: 56, height: 56 }}>
                <AccountCircleIcon fontSize="large" />
              </Avatar>
            </Box>
            <Typography variant="h4" gutterBottom align="center">
              {t("user_profile")}
              <Tooltip sx={{ ml: 2 }} title={t("del_user")}>
                <IconButton onClick={() => setOpen(true)}>
                  <DeleteIcon
                    sx={{
                      fill: theme.palette.error.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {t("first_name")}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{useInfo?.first_name}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {t("last_name")}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{useInfo?.last_name}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{useInfo?.email}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  Username:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{useInfo?.user_name}</Typography>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Button
                onClick={openPasswordModal}
                variant="contained"
                color="primary"
              >
                {t("change_password")}
              </Button>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default UserProfile;
