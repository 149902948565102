import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setMessage } from "../redux/slices/snackbarSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { store } from "../redux/store";

const ChangePasswordModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validation = useSelector((state) => state?.authSlice?.validation);
  const userId = useSelector((state) => state?.authSlice?.useInfo?.id);

  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [getValidation] = store.useLazyGetValidationQuery();
  const [changePassword, { isSuccess }] = store.useChangePasswordMutation();

  const [validationErrors, setValidationErrors] = useState({
    newPassword: [],
    confirmPassword: [],
  });

  useEffect(() => {
    if (!validation) {
      getValidation();
    }
  }, [validation]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("pass_changed")));
    }
  }, [isSuccess]);

  useEffect(() => {
    const passwordRules = {
      minLength: passwords.newPassword.length >= validation?.minLength.value,
      hasUpperCase: /[A-Z]/.test(passwords.newPassword),
      hasLowerCase: /[a-z]/.test(passwords.newPassword),
      hasNumbers: /\d/.test(passwords.newPassword),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(passwords.newPassword),
    };

    setValidationErrors({
      newPassword: passwordRules,
      confirmPassword: passwords.newPassword === passwords.confirmPassword,
    });
  }, [passwords.newPassword, passwords.confirmPassword]);

  const isFormValid =
    passwords.oldPassword.length > 0 &&
    passwords.newPassword.length > 0 &&
    passwords.confirmPassword.length > 0 &&
    validationErrors.newPassword.minLength &&
    validationErrors.newPassword.hasUpperCase &&
    validationErrors.newPassword.hasLowerCase &&
    validationErrors.newPassword.hasNumbers &&
    validationErrors.newPassword.hasSpecialChar &&
    passwords.newPassword === passwords.confirmPassword;

  const handleConfirm = () => {
    const attributes = {
      current_password: passwords.oldPassword,
      new_password: passwords.newPassword,
      password_confirmation: passwords.confirmPassword,
    };

    changePassword({ userId: userId, attributes: attributes });

    setPasswords({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    onClose();
  };

  useEffect(() => {
    console.log("valya", userId);
  }, [userId]);

  return (
    <Dialog open={visible} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("change_password")}</DialogTitle>
      <DialogContent>
        <Box display="flex" sx={{ mt: 2 }} flexDirection="column" gap={2}>
          {/* Old Password */}
          <Box position="relative">
            <TextField
              label={t("old_password")}
              type={showOldPassword ? "text" : "password"}
              fullWidth
              value={passwords.oldPassword}
              onChange={(e) =>
                setPasswords({ ...passwords, oldPassword: e.target.value })
              }
            />
            <IconButton
              onClick={() => setShowOldPassword(!showOldPassword)}
              style={{ position: "absolute", right: 10, top: 10 }}
            >
              {showOldPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>

          {/* New Password */}
          <Box position="relative">
            <TextField
              label={t("new_password")}
              type={showNewPassword ? "text" : "password"}
              fullWidth
              value={passwords.newPassword}
              onChange={(e) =>
                setPasswords({ ...passwords, newPassword: e.target.value })
              }
            />
            <IconButton
              onClick={() => setShowNewPassword(!showNewPassword)}
              style={{ position: "absolute", right: 10, top: 10 }}
            >
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>

          {/* Confirm Password */}
          <Box position="relative">
            <TextField
              label={t("confirm_password")}
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              value={passwords.confirmPassword}
              onChange={(e) =>
                setPasswords({ ...passwords, confirmPassword: e.target.value })
              }
            />
            <IconButton
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{ position: "absolute", right: 10, top: 10 }}
            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Box>

          {/* Validation Messages */}
          {validation && (
            <>
              <div>
                <Typography
                  style={{
                    color: validationErrors.newPassword.minLength
                      ? "green"
                      : "red",
                    marginBottom: "8px",
                  }}
                >
                  {validation.minLength.value === 1
                    ? t("validation.minLength", {
                        count: validation.minLength.value,
                      })
                    : t("validation.minLengthPlural", {
                        count: validation.minLength.value,
                      })}
                </Typography>

                <Typography
                  style={{
                    color: validationErrors.newPassword.hasUpperCase
                      ? "green"
                      : "red",
                    marginBottom: "8px",
                  }}
                >
                  {validation.uppercaseLength.value === 1
                    ? t("validation.uppercaseLength", {
                        count: validation.uppercaseLength.value,
                      })
                    : t("validation.uppercaseLengthPlural", {
                        count: validation.uppercaseLength.value,
                      })}
                </Typography>

                <Typography
                  style={{
                    color: validationErrors.newPassword.hasLowerCase
                      ? "green"
                      : "red",
                    marginBottom: "8px",
                  }}
                >
                  {validation.lowercaseLength.value === 1
                    ? t("validation.lowercaseLength", {
                        count: validation.lowercaseLength.value,
                      })
                    : t("validation.lowercaseLengthPlural", {
                        count: validation.lowercaseLength.value,
                      })}
                </Typography>

                <Typography
                  style={{
                    color: validationErrors.newPassword.hasNumbers
                      ? "green"
                      : "red",
                    marginBottom: "8px",
                  }}
                >
                  {validation.numberLength.value === 1
                    ? t("validation.numberLength", {
                        count: validation.numberLength.value,
                      })
                    : t("validation.numberLengthPlural", {
                        count: validation.numberLength.value,
                      })}
                </Typography>

                <Typography
                  style={{
                    color: validationErrors.newPassword.hasSpecialChar
                      ? "green"
                      : "red",
                    marginBottom: "8px",
                  }}
                >
                  {validation.symbolLength.value === 1
                    ? t("validation.symbolLength", {
                        count: validation.symbolLength.value,
                      })
                    : t("validation.symbolLengthPlural", {
                        count: validation.symbolLength.value,
                      })}
                </Typography>

                {/* Password Confirmation */}
                {passwords.newPassword.length > 0 &&
                  passwords.confirmPassword.length > 0 && (
                    <Typography
                      style={{
                        color:
                          passwords.newPassword === passwords.confirmPassword
                            ? "green"
                            : "red",
                        marginBottom: "8px",
                      }}
                    >
                      {passwords.newPassword === passwords.confirmPassword
                        ? t("validation.passwordsMatch")
                        : t("validation.passwordsDoNotMatch")}
                    </Typography>
                  )}
              </div>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button
          onClick={handleConfirm}
          disabled={!isFormValid}
          color="primary"
          variant="contained"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
