import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Box,
  Typography,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setMessage } from "../redux/slices/snackbarSlice";
import { useTransition } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import theme from "./theme";
import { store } from "../redux/store";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalDialog from "./utils/ModalDialog";
import { settingActions } from "../redux/slices/settingsSlice";
import { actions as authActions } from "../redux/slices/authSlice";

const headerStyles = {
  root: {
    flexGrow: 1,
    marginRight: 7,
    marginLeft: 7,
    marginBottom: 2,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  rightIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
};

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const preferences = useSelector((state) => state?.authApi?.preferences);
  const location = useLocation();
  const [logout, { isSuccess }] = store.useSignoutMutation();
  const dispatch = useDispatch();
  const open = useSelector((state) => state?.settingsSlice?.modal);
  const logged = useSelector((state) => state?.authSlice?.logged);
  const [getUserPreferences, { isSuccess: prefereceSuccess }] =
    store.useLazyGetMeQuery();
  const [updatePreferences, { isSuccess: updateSuccess }] =
    store.useUpdatePreferenceMutation();

  const userPreferences = useSelector((state) => state?.authApi?.useInfo);
  const meterMode = useSelector((state) => state?.authSlice?.meterMode);
  const apartmentMode = useSelector((state) => state?.authSlice?.apartmentMode);

  useEffect(() => {
    if (isSuccess) {
      navigate(`/login`);
    }
  }, [isSuccess]);
  const onHomeClick = () => {
    if (location.pathname !== "/" && location.pathname !== "/login") {
      navigate("/");
    }
  };

  const meterModeHandleChange = (e) => {
    dispatch(authActions.setMeterMode(e.target.value));
  };
  const apartmentModeHandleChange = (e) => {
    dispatch(authActions.setApartmentMode(e.target.value));
  };
  useEffect(() => {
    if (!userPreferences && logged) {
      getUserPreferences();
    }
  }, [userPreferences, logged]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage(t("successfully_updated")));
      dispatch(settingActions?.setModal(false));
    }
  }, [updateSuccess]);
  const onSavePreferenceClick = () => {
    const attributes = {
      ...preferences,
      meter_mode: meterMode,
      apartment_mode: apartmentMode,
    };
    updatePreferences(attributes);
  };

  const escludedPats = ["/login", "/reset_password", "/registration"];

  return (
    <>
      {!escludedPats.includes(location?.pathname) && (
        <Box style={headerStyles.root}>
          {prefereceSuccess && (
            <ModalDialog
              open={open}
              title={t("settings")}
              close={() => dispatch(settingActions?.setModal(false))}
              cancell={false}
            >
              <Box sx={{ minWidth: 420 }}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    {t("apartment_mode")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={apartmentMode}
                    label={t("apartment_mode")}
                    onChange={apartmentModeHandleChange}
                  >
                    <MenuItem value={"card"}>{t("card")}</MenuItem>
                    <MenuItem value={"table"}>{t("table")}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    {t("meter_mode")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={meterMode}
                    label={t("meter_mode")}
                    onChange={meterModeHandleChange}
                  >
                    <MenuItem value={"card"}>{t("card")}</MenuItem>
                    <MenuItem value={"table"}>{t("table")}</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={onSavePreferenceClick}
                  sx={{ mt: 2, float: "right" }}
                  variant="contained"
                >
                  {t("save")}
                </Button>
              </Box>
            </ModalDialog>
          )}
          <AppBar
            position="static"
            sx={{ background: theme.palette.primary.main }}
          >
            <Toolbar sx={headerStyles.toolbar}>
              {/* Логотип */}
              {window?.LOGO?.logo ? (
                <img
                  onClick={onHomeClick}
                  style={{
                    height: window?.LOGO?.height,
                    width: window?.LOGO?.width,
                    marginRight: 20,
                    marginTop: 5,
                    fill: window?.LOGO?.color,
                    cursor: "pointer",
                  }}
                  src={window?.LOGO?.logo}
                />
              ) : (
                "LOGO"
              )}

              <Box sx={headerStyles.rightIconsContainer}>
                <Tooltip title="Logout">
                  <IconButton
                    onClick={() => logout()}
                    disabled={location.pathname === "/registration"}
                  >
                    <LogoutIcon
                      sx={{
                        fill: location.pathname !== "/registration" && "#ffff",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("user_profile")}>
                  <IconButton
                    onClick={() => navigate("/me")}
                    disabled={location.pathname === "/registration"}
                  >
                    <AccountCircleIcon
                      sx={{
                        fill: location.pathname !== "/registration" && "#ffff",
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("settings")}>
                  <IconButton
                    onClick={() => dispatch(settingActions?.setModal(true))}
                    disabled={
                      location.pathname === "/registration" || !prefereceSuccess
                    }
                  >
                    <Brightness5Icon
                      sx={{
                        fill:
                          location.pathname !== "/registration" &&
                          prefereceSuccess &&
                          "#ffff",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}
    </>
  );
}

export default Header;
